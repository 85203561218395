.currency-flag {
  background-image: url(//aquaholics.co.uk/cdn/shop/t/27/assets/country-flags-40.png);
  background-repeat: no-repeat;
  background-position: top left;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(0,0,0,0.3) inset;
  border-radius: 50%;
  background-clip: padding-box; /* Fix for android devices */
}

.currency-flag.currency-flag--european_union {
  background-position: 0 -3950px;

}
.currency-flag.currency-flag--gb-eng {
  background-position: 0 -4400px;
}

